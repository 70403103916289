<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">现勘管理</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp a-mlr-17" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main sceneCheckContent">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
                <le-input label="项目名称" v-model="pageParam.params.key" />
                <le-input-district label="省市区" :province.sync="pageParam.params.provinceCode" :city.sync="pageParam.params.cityCode" :area.sync="pageParam.params.districtCode"></le-input-district>
                <le-company-under-select label="所属商户" v-model="pageParam.params.companyId"></le-company-under-select>
            </le-search-form>
            <le-pagview ref="sceneCheckList" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.sceneCheckListTable">
                <el-table ref="sceneCheckListTable" :data="tableData" :highlight-current-row="true"  style="width: 100%" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column prop="areaName" label="项目名称" min-width="200">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue font-point" @click="getInfo(row)">{{ row.areaName?row.areaName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="areaAddress" label="地址" min-width="200"></el-table-column>
                    <el-table-column prop="companyName" label="所属商户" min-width="200"></el-table-column>
                    <el-table-column prop="createTimeText" label="现勘日期" min-width="103"></el-table-column>
                    <el-table-column prop="userName" label="现勘人员" min-width="103"></el-table-column>
                    <el-table-column prop="stationSize" label="站点数" min-width="103"></el-table-column>
                    <el-table-column prop="name" label="完整度" min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.percent?row.percent:0 }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="statusText" 
                        min-width="120"
                        column-key="auditStatus"
                        :filter-multiple='false'
                        :filters="auditStatusDic">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-rfsc">
                                <span>审核状态</span>
                                <img style="width:15px;height:15px;margin-left:7px" src="../../assets/icon/filter-icon.png" alt="">
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="75" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="getInfo(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        data () {
            return {
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getSurveyArea,
                    method: "post",
                    params: {
                        currentCompanyId: '',
                        companyId: '',
                        key: '',
                        provinceCode: '',
                        cityCode: '',
                        districtCode: '',
                        checkStatus: ''
                    },
                    freshCtrl: 1,
                },
                auditStatusDic: [{
                    text: '待审核', 
                    value: 1
                },{
                    text: '审核拒绝', 
                    value: 2
                },{
                    text: '已回执', 
                    value: 3
                }],//审核状态
                fileUrl: ''
            }
        },
        computed: {
            ...mapState({
                company: state => state.company.company
            })
        },
        created () {
            this.pageParam.params.currentCompanyId = this.company.id
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data,fileUrl) {
                this.tableData = data;
                this.fileUrl = fileUrl
            },
            handlerRest() {
                this.pageParam.params = {
                    currentCompanyId: '',
                    companyId: '',
                    key: '',
                    provinceCode: '',
                    cityCode: '',
                    districtCode: '',
                    checkStatus: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['sceneCheckList'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='auditStatus'){
                        if(value[key].length==0){
                            this.pageParam.params.checkStatus = ''
                        }else{
                            this.pageParam.params.checkStatus = value[key][0]
                        }
                    }
                }
                this.handlerSearch()
            },
            // 查看现勘详情
            getInfo (datas) {
                this.$router.push({
                    path: '/sceneCheck/sceneCheck-info',
                    query: {
                        id: datas.id,
                        title: datas.areaName
                    }
                })
            },
            exportfile () {
                this.$exfile({
                    code: 11,
                    fileName: '现勘小区站点信息',
                    params: {
                        ...this.pageParam.params,
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
/deep/ .sceneCheckContent{
    .el-table__column-filter-trigger{
        display: none;
    }
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>